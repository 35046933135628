<template>
  <div :class="$style.login">
    <transition name="topEnter" mode="out-in">
      <img v-if="loading" key="1" src="@/assets/icons/loader.svg" alt="loader" />

      <div v-else-if="!loading && step === null" key="2" :class="$style.login__form">
        <Button
          :class="$style.login__form_button"
          type="secondary"
          @click="step = 'authorization'"
        >
          Авторизация
        </Button>
        <span>- или -</span>
        <Button
          :class="$style.login__form_button"
          type="secondary"
          @click="step = 'registration'"
        >
          Регистрация
        </Button>
      </div>

      <div
        v-else-if="!loading && step !== null"
        key="3"
        :class="[
          $style.login__form,
          { [$style.login__form_registration]: step === 'registration' }
        ]"
      >
        <Input
          :class="$style.login__form_input"
          v-model="login"
          name="Логин"
          :error="error.login && !firstFill"
          @input="validation(login, 'login')"
        />
        <Input
          :class="$style.login__form_input"
          v-model="password"
          type="password"
          :name="step === 'authorization' ? 'Пароль' : 'Введите пароль из почты'"
          :error="error.password && !firstFill"
          @input="validation(password, 'password')"
        />
        <Input
          v-if="step === 'registration'"
          :class="$style.login__form_input"
          v-model="newPassword"
          type="password"
          name="Новый пароль"
          :error="error.newPassword && !firstFill"
          @input="validation(newPassword, 'newPassword')"
        />
        <div :class="$style.login__form_wrapper">
          <Button :class="$style.login__form_button_back" type="secondary" @click="goTo(null)">
            Назад
          </Button>
          <Button :class="$style.login__form_button" type="secondary" @click="submit">
            {{ step === 'authorization' ? 'Войти' : 'Зарегистрироваться' }}
          </Button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Input from '@/basic/Input'
import Button from '@/basic/Button'

import authApi from '@/api/auth'

import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    Input,
    Button
  },
  data() {
    return {
      login: '',
      password: '',
      newPassword: '',
      error: {
        login: true,
        password: true,
        newPassword: true
      },
      loading: false,
      step: null,
      firstFill: true
    }
  },
  computed: {
    ...mapGetters(['authToken'])
  },
  async created() {
    const token = this.$cookies.get('x-token')

    if (this.authToken && token) {
      const path = this.$cookies.get('follow-path') ?? '/orders'
      this.$router.push(path)
    }
  },
  methods: {
    ...mapMutations(['setAuthToken']),
    ...mapActions(['getEmployeeProfile', 'getConstants', 'getInit']),

    goTo(step) {
      this.clearErrors()
      this.login = ''
      this.password = ''
      this.newPassword = ''
      this.firstFill = true
      this.step = step
    },

    validation(value, name) {
      if (value.length >= 2) {
        this.error[name] = false
      } else {
        this.error[name] = true
      }
    },

    clearErrors() {
      for (const key in this.error) {
        this.error[key] = true
      }
    },

    async authorization() {
      try {
        const sendData = {
          login: this.login,
          password: this.password
        }
        if (this.step === 'registration') {
          sendData.newPassword = this.newPassword
        }
        const { data } = await authApi.authorization(sendData)
        if (data?.success) {
          this.$cookies.set('x-token', data.result.refreshToken, '72h')
          sessionStorage.setItem('x-access-token', data.result.accessToken)
          this.setAuthToken(data.result.accessToken)
          this.axios.defaults.headers['x-access-token'] = data.result.accessToken
        }

        this.$socket.io.opts.extraHeaders['x-access-token'] = this.authToken
        this.$socket.open()
      } catch (error) {
        console.warn(error)
        this.$cookies.remove('x-token')
        sessionStorage.removeItem('x-access-token')
        this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message:
              this.step === 'authorization'
                ? 'Ошибка авторизации'
                : error?.response?.data?.result?.message === 'Account already activated'
                ? 'Аккаунт уже зарегистрирован'
                : 'Ошибка регистрации'
          }
        ])
        throw new Error(error)
      }
    },

    async submit() {
      if (this.step === 'authorization' && (this.error.login || this.error.password)) {
        this.firstFill = false
        return
      }

      if (
        this.step === 'registration' &&
        (this.error.login || this.error.password || this.error.newPassword)
      ) {
        this.firstFill = false
        return
      }

      try {
        this.loading = true
        await this.authorization()
        await this.getEmployeeProfile()
        await this.getConstants()
        await this.getInit()
        const path = this.$cookies.get('follow-path') ?? '/orders'
        this.$router.push(path)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" module>
.login {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 25rem;
    height: 25%;
    &_registration {
      height: 30%;
      .login__form_button {
        width: 13.25rem;
      }
      .login__form_button_back {
        min-width: 9rem;
      }
    }
    &_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    &_input {
      width: 100%;
      margin-left: 0 !important;
    }
    &_button {
      width: 11.25rem;
    }
  }
}
</style>
